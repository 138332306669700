import React from 'react'
import { useHistory } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'
import DateLabel from 'components/common/DateLabel'
import { Grid } from '@material-ui/core'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import { ApplicationTypes } from 'utils/enum'
import { fromCode } from 'utils/enum.utils'

const useStyles = makeStyles(theme => ({
  '.MuiDrawer-paper': {
    maxHeight: '600px'
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  list: {
    width: '400px'
  },
  container: {
    maxHeight: 540,
    maxWidth: '100%'
  },
  large: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  medium: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  }
}))

export default function NoticeList({ toggleDrawer, items, isLoading }) {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = (id, type) => {
    toggleDrawer()
    let url = ''
    switch (type) {
      case ApplicationTypes.residentInfoChange.code:
        url = `/application/residentInfoChange/detail/${id}`
        break
      case ApplicationTypes.residentCancellation.code:
        url = `/application/residentCancellation/detail/${id}`
        break
      case ApplicationTypes.roomCheckList.code:
        url = `/application/roomCheckList/detail/${id}`
        break
      case ApplicationTypes.bicycleParkingStickers.code:
        url = `/application/bicycleParkingSticker/detail/${id}`
        break
      case ApplicationTypes.certificateOfConsentToUseParkingSpace.code:
        url = `/application/cOC2UseParking/detail/${id}`
        break
      case ApplicationTypes.pet.code:
        url = `/application/pet/detail/${id}`
        break
      case ApplicationTypes.keyReceipt.code:
        url = `/application/keyReceipt/detail/${id}`
        break
    }
    history.push(url)
  }

  return (
    <div className={classes.list} role="presentation">
      <Toolbar>
        <span style={{ marginRight: '10px', color: 'red' }}>
          <NewReleasesIcon />
        </span>
        <Typography className={classes.title} variant="subtitle1">
          未対応申請
          <br />
          <Typography variant="caption" display="block" gutterBottom>
            最新{items.length}件
          </Typography>
        </Typography>

        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={'createdAt'}
                style={{
                  width: '30%'
                }}
              >
                受信日時
              </TableCell>
              <TableCell
                key={'category'}
                style={{
                  width: '70%'
                }}
              >
                種類
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {items.map(row => {
                console.log(row)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => handleClick(row.id, row.application_type_id)}
                  >
                    <TableCell>
                      <DateLabel timestamp={row.created_at} />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <CellWithType type={row.application_type_id} />
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
        {isLoading && <LinearProgress style={{ width: '100%' }} />}
      </TableContainer>
    </div>
  )
}

function CellWithType({ type }) {
  const applicationTypes = fromCode(ApplicationTypes, type)
  return applicationTypes.value
}
