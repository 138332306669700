/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const authIpAddress = /* GraphQL */ `
  mutation AuthIpAddress {
    authIpAddress {
      body
    }
  }
`
export const authOriginalUrl = /* GraphQL */ `
  mutation AuthOriginalUrl($originalUrl: String!) {
    authOriginalUrl(originalUrl: $originalUrl) {
      body
    }
  }
`
export const authSignin = /* GraphQL */ `
  mutation AuthSignin($mail: String!, $password: String!, $subDomain: String!) {
    authSignin(mail: $mail, password: $password, subDomain: $subDomain) {
      user_id
      company_id
      role_id
      sub_domain
      identityId
      token
      is_administrator
    }
  }
`
export const authSubDomain = /* GraphQL */ `
  mutation AuthSubDomain($subDomain: String!) {
    authSubDomain(subDomain: $subDomain) {
      noop
    }
  }
`
export const ccProxySignin = /* GraphQL */ `
  mutation CcProxySignin($input: CCProxySigninInput!) {
    ccProxySignin(input: $input) {
      user_id
      company_id
      role_id
      sub_domain
      identityId
      token
      is_administrator
    }
  }
`
export const tokenRefresh = /* GraphQL */ `
  mutation TokenRefresh($identityId: String!, $token: String!) {
    tokenRefresh(identityId: $identityId, token: $token) {
      user_id
      company_id
      role_id
      sub_domain
      identityId
      token
      is_administrator
    }
  }
`
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      status
      name
      name_kana
      stock_signage
      zip_code
      prefecture_id
      address
      tel
      fax
      mail
      url
      business_hours
      owner_function_enabled
      regular_holiday
      emergency_name
      emergency_tel
      emergency_time
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      business_hours_text
      regular_holiday_text
      emergency_time_text
      privacy_policy_url
    }
  }
`
export const createCompanyGroup = /* GraphQL */ `
  mutation CreateCompanyGroup($input: CreateCompanyGroupInput!) {
    createCompanyGroup(input: $input) {
      id
      status
      company_id
      role_id
      name
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateCompanyGroup = /* GraphQL */ `
  mutation UpdateCompanyGroup($input: UpdateCompanyGroupInput!) {
    updateCompanyGroup(input: $input) {
      id
      status
      company_id
      role_id
      name
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createCompanyUser = /* GraphQL */ `
  mutation CreateCompanyUser($input: CreateCompanyUserInput!) {
    createCompanyUser(input: $input) {
      attachment_id
      company_group_id
      company_id
      created_at
      deleted_at
      display_name
      id
      is_administrator
      mail_address
      name
      password
      status
      type_id
      updated_at
      username
    }
  }
`
export const updateCompanyUser = /* GraphQL */ `
  mutation UpdateCompanyUser($input: UpdateCompanyUserInput!) {
    updateCompanyUser(input: $input) {
      attachment_id
      company_group_id
      company_id
      created_at
      deleted_at
      display_name
      id
      is_administrator
      mail_address
      name
      password
      status
      type_id
      updated_at
      username
    }
  }
`
export const createBookmark = /* GraphQL */ `
  mutation CreateBookmark($input: CreateBookmarkInput!) {
    createBookmark(input: $input) {
      id
      status
      company_id
      attachment_id
      name
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateBookmark = /* GraphQL */ `
  mutation UpdateBookmark($input: UpdateBookmarkInput!) {
    updateBookmark(input: $input) {
      id
      status
      company_id
      attachment_id
      name
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createOwner = /* GraphQL */ `
  mutation CreateOwner($input: OwnerInput!) {
    createOwner(input: $input) {
      id
      name
      name_kana
      app_user_id
      company_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createOwnerBuilding = /* GraphQL */ `
  mutation CreateOwnerBuilding($input: CreateOwnerBuildingInput!) {
    createOwnerBuilding(input: $input) {
      id
      owner_id
      building_id
      is_usage
      created_at
      updated_at
      deleted_at
    }
  }
`
export const upsertOwnerAttachment = /* GraphQL */ `
  mutation UpsertOwnerAttachment($input: OwnerAttachmentListInput) {
    upsertOwnerAttachment(input: $input) {
      id
      owner_building_id
      attachment_id
      attachment_category_id
    }
  }
`
export const updateOwner = /* GraphQL */ `
  mutation UpdateOwner($input: OwnerInput!) {
    updateOwner(input: $input) {
      id
      name
      name_kana
      app_user_id
      company_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateOwnerBuilding = /* GraphQL */ `
  mutation UpdateOwnerBuilding($input: UpdateOwnerBuildingInput!) {
    updateOwnerBuilding(input: $input) {
      id
      owner_id
      building_id
      is_usage
      created_at
      updated_at
      deleted_at
    }
  }
`
export const deleteOwnerBuilding = /* GraphQL */ `
  mutation DeleteOwnerBuilding($input: DeleteOwnerBuildingInput!) {
    deleteOwnerBuilding(input: $input) {
      noop
    }
  }
`
export const updateBuilding = /* GraphQL */ `
  mutation UpdateBuilding($input: UpdateBuildingInput!) {
    updateBuilding(input: $input) {
      id
      status
      company_id
      tty_id
      tty_isp_tty_id
      is_management
      remark
      room_cnt
      contract_cnt
      customer_cnt
      display_order
      created_at
      updated_at
      deleted_at
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
    }
  }
`
export const updateBuildingManagement = /* GraphQL */ `
  mutation UpdateBuildingManagement($input: UpdateMstTateyaMergeInput!) {
    updateBuildingManagement(input: $input) {
      TTY_ISP_TTY_ID
      TTY_ID
      TTY_KNR_DIV
      is_management
    }
  }
`
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      id
      status
      company_id
      building_id
      hya_id
      remark
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createPackage = /* GraphQL */ `
  mutation CreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      id
      status
      company_id
      name
      description
      body
      title_attachment_id
      url
      link_text
      display_order
      is_display_top
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
    }
  }
`
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      id
      status
      company_id
      name
      description
      body
      title_attachment_id
      url
      link_text
      display_order
      is_display_top
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
    }
  }
`
export const updateContract = /* GraphQL */ `
  mutation UpdateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      status
      company_id
      building_id
      room_id
      contractor_id
      tenant_id
      kyk_id
      kyk_status_div
      kyk_keiyaku_str_date
      kyk_keiyaku_end_date
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      status
      company_id
      kky_id
      app_user_id
      username
      password
      iot_provider_id
      is_usage
      is_activation
      registered_at
      remark
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createCustomerHistoryThread = /* GraphQL */ `
  mutation CreateCustomerHistoryThread(
    $input: CreateCustomerHistoryThreadInput!
  ) {
    createCustomerHistoryThread(input: $input) {
      id
      original_data_id
      created_at
      status
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      application_type {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
      other_activity_type {
        id
        name
        created_at
        updated_at
        deleted_at
      }
      type
      updated_at
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const updateCustomerHistoryThread = /* GraphQL */ `
  mutation UpdateCustomerHistoryThread(
    $input: UpdateCustomerHistoryThreadInput!
  ) {
    updateCustomerHistoryThread(input: $input) {
      id
      original_data_id
      created_at
      status
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      application_type {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
      other_activity_type {
        id
        name
        created_at
        updated_at
        deleted_at
      }
      type
      updated_at
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const createCustomerHistoryMessage = /* GraphQL */ `
  mutation CreateCustomerHistoryMessage(
    $input: CreateCustomerHistoryMessageInput!
  ) {
    createCustomerHistoryMessage(input: $input) {
      customer_history_thread_id
      customer_history_message_type_id
      message
      created_at
      updated_user
      updated_user_status
    }
  }
`
export const updateCustomerHistoryMessage = /* GraphQL */ `
  mutation UpdateCustomerHistoryMessage(
    $input: UpdateCustomerHistoryMessageInput!
  ) {
    updateCustomerHistoryMessage(input: $input) {
      customer_history_thread_id
      customer_history_message_type_id
      message
      created_at
      updated_user
      updated_user_status
    }
  }
`
export const createBulletinBoard = /* GraphQL */ `
  mutation CreateBulletinBoard($input: CreateBulletinBoardInput!) {
    createBulletinBoard(input: $input) {
      id
      status
      company_id
      bulletin_board_type_id
      is_important
      title
      body
      url
      display_start_at
      display_end_at
      is_assign
      display_order
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      with_owner
      url_text
    }
  }
`
export const updateBulletinBoard = /* GraphQL */ `
  mutation UpdateBulletinBoard($input: UpdateBulletinBoardInput!) {
    updateBulletinBoard(input: $input) {
      id
      status
      company_id
      bulletin_board_type_id
      is_important
      title
      body
      url
      display_start_at
      display_end_at
      is_assign
      display_order
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      with_owner
      url_text
    }
  }
`
export const createThread = /* GraphQL */ `
  mutation CreateThread($input: CreateThreadInput!) {
    createThread(input: $input) {
      id
      status
      company_id
      category_id
      application_id
      customer_id
      building_id
      room_id
      thread_state_id
      is_reply
      assigned_group_by
      assigned_by
      exchanged_at
      exchanged_by
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateThread = /* GraphQL */ `
  mutation UpdateThread($input: UpdateThreadInput!) {
    updateThread(input: $input) {
      id
      status
      company_id
      category_id
      application_id
      customer_id
      building_id
      room_id
      thread_state_id
      is_reply
      assigned_group_by
      assigned_by
      exchanged_at
      exchanged_by
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        id
        status
        company_id
        thread_id
        customer_id
        company_user_id
        message_type_id
        body
        is_read
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      message {
        id
        status
        company_id
        thread_id
        customer_id
        company_user_id
        message_type_id
        body
        is_read
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const createDataLinkHistory = /* GraphQL */ `
  mutation CreateDataLinkHistory($input: CreateDataLinkHistoryInput!) {
    createDataLinkHistory(input: $input) {
      data_link_history {
        id
        status
        company_id
        data_link_type_id
        data_link_state_id
        data_type_id
        attachment_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      error_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const updateDataLinkHistory = /* GraphQL */ `
  mutation UpdateDataLinkHistory($input: UpdateDataLinkHistoryInput!) {
    updateDataLinkHistory(input: $input) {
      data_link_history {
        id
        status
        company_id
        data_link_type_id
        data_link_state_id
        data_type_id
        attachment_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      error_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate($input: CreateMessageTemplateInput!) {
    createMessageTemplate(input: $input) {
      id
      status
      company_id
      category_id
      title
      body
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate($input: UpdateMessageTemplateInput!) {
    updateMessageTemplate(input: $input) {
      id
      status
      company_id
      category_id
      title
      body
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createFaqAndAttachments = /* GraphQL */ `
  mutation CreateFaqAndAttachments($input: CreateFaqInput!) {
    createFaqAndAttachments(input: $input) {
      faq {
        id
        status
        company_id
        category_id
        question
        answer
        display_order
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
      faqAttachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const updateFaqAndAttachments = /* GraphQL */ `
  mutation UpdateFaqAndAttachments($input: UpdateFaqInput!) {
    updateFaqAndAttachments(input: $input) {
      faq {
        id
        status
        company_id
        category_id
        question
        answer
        display_order
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
      faqAttachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const updatePetApplication = /* GraphQL */ `
  mutation UpdatePetApplication($input: UpdatePetApplicationInput!) {
    updatePetApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateCoc2UseParkingApplication = /* GraphQL */ `
  mutation UpdateCoc2UseParkingApplication(
    $input: UpdateCoc2UseParkingApplicationInput!
  ) {
    updateCoc2UseParkingApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateBicycleParkingStickerApplication = /* GraphQL */ `
  mutation UpdateBicycleParkingStickerApplication(
    $input: UpdateBicycleParkingStickerApplicationInput!
  ) {
    updateBicycleParkingStickerApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateResidentInfoChangeApplication = /* GraphQL */ `
  mutation UpdateResidentInfoChangeApplication(
    $input: UpdateResidentInfoChangeApplicationInput!
  ) {
    updateResidentInfoChangeApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateResidentCancellationApplication = /* GraphQL */ `
  mutation UpdateResidentCancellationApplication(
    $input: UpdateResidentCancellationApplicationInput!
  ) {
    updateResidentCancellationApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateRoomCheckListApplication = /* GraphQL */ `
  mutation UpdateRoomCheckListApplication(
    $input: UpdateRoomCheckListApplicationInput!
  ) {
    updateRoomCheckListApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createTotonoSessionDev = /* GraphQL */ `
  mutation CreateTotonoSessionDev($input: CreateTotonoSessionDevInput!) {
    createTotonoSessionDev(input: $input) {
      token
      type
      user
      company
    }
  }
`
export const updateTotonoSessionDev = /* GraphQL */ `
  mutation UpdateTotonoSessionDev($input: UpdateTotonoSessionDevInput!) {
    updateTotonoSessionDev(input: $input) {
      token
      type
      user
      company
    }
  }
`
export const deleteTotonoSessionDev = /* GraphQL */ `
  mutation DeleteTotonoSessionDev($input: DeleteTotonoSessionDevInput!) {
    deleteTotonoSessionDev(input: $input) {
      token
      type
      user
      company
    }
  }
`
export const updateAppUserAccount = /* GraphQL */ `
  mutation UpdateAppUserAccount($input: UpdateAppUserAccountInput!) {
    updateAppUserAccount(input: $input) {
      id
      status
      username
      password
      is_activation
      registered_at
      attachment_id
      last_login_at
      created_at
      updated_at
      deleted_at
    }
  }
`
export const registerAppUserAccount = /* GraphQL */ `
  mutation RegisterAppUserAccount($input: RegisterAppUserAccountInput!) {
    registerAppUserAccount(input: $input) {
      id
      status
      username
      password
      is_activation
      registered_at
      attachment_id
      last_login_at
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createMessageAutoReply = /* GraphQL */ `
  mutation CreateMessageAutoReply($input: CreateMessageAutoReplyInput!) {
    createMessageAutoReply(input: $input) {
      id
      status
      company_id
      start
      end
      message_body
      force_auto_reply
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createAllCompanyCloseWeek = /* GraphQL */ `
  mutation CreateAllCompanyCloseWeek($input: CreateAllCompanyCloseWeekInput!) {
    createAllCompanyCloseWeek(input: $input) {
      data {
        id
        status
        company_id
        week_id
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const createAllCompanyHoliday = /* GraphQL */ `
  mutation CreateAllCompanyHoliday($input: CreateAllCompanyHolidayInput!) {
    createAllCompanyHoliday(input: $input) {
      data {
        id
        status
        company_id
        holiday
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const updateMessageAutoReply = /* GraphQL */ `
  mutation UpdateMessageAutoReply($input: UpdateMessageAutoReplyInput!) {
    updateMessageAutoReply(input: $input) {
      id
      status
      company_id
      start
      end
      message_body
      force_auto_reply
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateAllCompanyCloseWeek = /* GraphQL */ `
  mutation UpdateAllCompanyCloseWeek($input: UpdateAllCompanyCloseWeekInput!) {
    updateAllCompanyCloseWeek(input: $input) {
      data {
        id
        status
        company_id
        week_id
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const updateAllCompanyHoliday = /* GraphQL */ `
  mutation UpdateAllCompanyHoliday($input: UpdateAllCompanyHolidayInput!) {
    updateAllCompanyHoliday(input: $input) {
      data {
        id
        status
        company_id
        holiday
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const createCancellationApplicationOption = /* GraphQL */ `
  mutation CreateCancellationApplicationOption(
    $input: CreateCancellationApplicationOptionInput!
  ) {
    createCancellationApplicationOption(input: $input) {
      id
      status
      company_id
      content
      url
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
    }
  }
`
export const updateCancellationApplicationOption = /* GraphQL */ `
  mutation UpdateCancellationApplicationOption(
    $input: UpdateCancellationApplicationOptionInput!
  ) {
    updateCancellationApplicationOption(input: $input) {
      id
      status
      company_id
      content
      url
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
    }
  }
`
export const createCompanyNotificationSettings = /* GraphQL */ `
  mutation CreateCompanyNotificationSettings(
    $input: CreateCompanyNotificationInput!
  ) {
    createCompanyNotificationSettings(input: $input) {
      categories {
        category_id
        company_id
        mail_address
      }
      applications {
        application_type_id
        company_id
        mail_address
      }
    }
  }
`
export const createBulkDeliveryMessages = /* GraphQL */ `
  mutation CreateBulkDeliveryMessages($input: CreateBulkDeliveryMessagesInput) {
    createBulkDeliveryMessages(input: $input) {
      id
      name
      send_counts
      type
      status
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      send_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      send_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      answer_button_type
      send_date_setting
      send_date
      sent_status
      updated_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      created_at
      updated_at
    }
  }
`
export const createThreadMemo = /* GraphQL */ `
  mutation CreateThreadMemo($input: CreateThreadMemoInput!) {
    createThreadMemo(input: $input) {
      id
      thread_id
      memo
      updater {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      created_at
      updated_at
      deleted_at
    }
  }
`
export const deleteBulkDeliveryMessages = /* GraphQL */ `
  mutation DeleteBulkDeliveryMessages(
    $input: DeleteBulkDeliveryMessagesInput!
  ) {
    deleteBulkDeliveryMessages(input: $input) {
      noop
    }
  }
`
export const deleteBulkDeliveryTemplate = /* GraphQL */ `
  mutation DeleteBulkDeliveryTemplate(
    $input: DeleteBulkDeliveryTemplateInput!
  ) {
    deleteBulkDeliveryTemplate(input: $input) {
      noop
    }
  }
`
export const deleteThreadMemo = /* GraphQL */ `
  mutation DeleteThreadMemo($input: DeleteThreadMemoInput!) {
    deleteThreadMemo(input: $input) {
      noop
    }
  }
`
export const sendBulkDeliveryMessages = /* GraphQL */ `
  mutation SendBulkDeliveryMessages($input: SendBulkDeliveryMessagesInput!) {
    sendBulkDeliveryMessages(input: $input) {
      noop
    }
  }
`
export const createBulkDeliveryMessagesDraft = /* GraphQL */ `
  mutation CreateBulkDeliveryMessagesDraft(
    $input: CreateBulkDeliveryMessagesDraftInput!
  ) {
    createBulkDeliveryMessagesDraft(input: $input) {
      noop
    }
  }
`
export const createBulkDeliveryTemplate = /* GraphQL */ `
  mutation CreateBulkDeliveryTemplate(
    $input: CreateBulkDeliveryTemplateInput!
  ) {
    createBulkDeliveryTemplate(input: $input) {
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      id
      title
      body
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      answer_button_type
      sent_status
      updater_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_at
    }
  }
`
export const reserveBulkDeliveryMessages = /* GraphQL */ `
  mutation ReserveBulkDeliveryMessages(
    $input: ReserveBulkDeliveryMessagesInput!
  ) {
    reserveBulkDeliveryMessages(input: $input) {
      noop
    }
  }
`
export const updateBulkDeliveryMessage = /* GraphQL */ `
  mutation UpdateBulkDeliveryMessage($input: UpdateBulkDeliveryMessageInput!) {
    updateBulkDeliveryMessage(input: $input) {
      id
      kyk_status_div
      mst_kokyaku {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      knr_keiyaku {
        KYK_ID
        KYK_HYA_ID
        KYK_MSK_ID
        KYK_ISP_KYK_ID
        KYK_INSERTED_DATE
        KYK_UPDATED_DATE
        KYK_DEL_FLG
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_KEIYAKU_DATE
        KYK_NYUKYO_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KEIYAKU_END_DATE
        KYK_REMARK
        KYK_FREERENT_FLG
        KYK_TINRYO_HASSEI_DATE
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHINRYO_MN
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_YR
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_DATE
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
      }
      body
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      is_edited
    }
  }
`
export const updateBulkDeliveryTemplate = /* GraphQL */ `
  mutation UpdateBulkDeliveryTemplate(
    $input: UpdateBulkDeliveryTemplateInput!
  ) {
    updateBulkDeliveryTemplate(input: $input) {
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      id
      title
      body
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      answer_button_type
      sent_status
      updater_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_at
    }
  }
`
export const updateThreadMemo = /* GraphQL */ `
  mutation UpdateThreadMemo($input: UpdateThreadMemoInput!) {
    updateThreadMemo(input: $input) {
      id
      thread_id
      memo
      updater {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createArrangement = /* GraphQL */ `
  mutation CreateArrangement($input: CreateArrangementInput!) {
    createArrangement(input: $input) {
      id
      external_assigned_group_by {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      external_assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_status
      body
      arrangement_memo_updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_memo_updated_at
    }
  }
`
export const updateArrangement = /* GraphQL */ `
  mutation UpdateArrangement($input: UpdateArrangementInput!) {
    updateArrangement(input: $input) {
      id
      external_assigned_group_by {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      external_assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_status
      body
      arrangement_memo_updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_memo_updated_at
    }
  }
`
export const updateArrangementMemo = /* GraphQL */ `
  mutation UpdateArrangementMemo($input: UpdateArrangementMemoInput!) {
    updateArrangementMemo(input: $input) {
      arrangement_id
      arrangement_type
      original_data_id
      body
    }
  }
`
export const deleteArrangementMemo = /* GraphQL */ `
  mutation DeleteArrangementMemo($input: DeleteArrangementMemoInput!) {
    deleteArrangementMemo(input: $input) {
      noop
    }
  }
`
export const createApplicationMemo = /* GraphQL */ `
  mutation CreateApplicationMemo($input: CreateApplicationMemoInput!) {
    createApplicationMemo(input: $input) {
      id
      application_id
      memo
      company_user_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateApplicationMemo = /* GraphQL */ `
  mutation UpdateApplicationMemo($input: UpdateApplicationMemoInput!) {
    updateApplicationMemo(input: $input) {
      id
      application_id
      memo
      company_user_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export const deleteApplicationMemo = /* GraphQL */ `
  mutation DeleteApplicationMemo($input: DeleteApplicationMemoInput!) {
    deleteApplicationMemo(input: $input) {
      noop
    }
  }
`
export const createOtherCustomerActivity = /* GraphQL */ `
  mutation CreateOtherCustomerActivity(
    $input: CreateOtherCustomerActivityInput!
  ) {
    createOtherCustomerActivity(input: $input) {
      assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      assigned_group_by {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      category_id
      created_at
      customer_id
      id
      memo
      other_activity_state
      other_activity_type
      receipted_at
      updated_at
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const updateOtherCustomerActivity = /* GraphQL */ `
  mutation UpdateOtherCustomerActivity(
    $input: UpdateOtherCustomerActivityInput!
  ) {
    updateOtherCustomerActivity(input: $input) {
      assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      assigned_group_by {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      category_id
      created_at
      customer_id
      id
      memo
      other_activity_state
      other_activity_type
      receipted_at
      updated_at
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const createOtherCustomerActivityMemo = /* GraphQL */ `
  mutation CreateOtherCustomerActivityMemo(
    $input: CreateOtherCustomerActivityMemoInput!
  ) {
    createOtherCustomerActivityMemo(input: $input) {
      created_at
      id
      memo
      other_activity_id
      updated_at
      updater {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const upsertApplicationOption = /* GraphQL */ `
  mutation UpsertApplicationOption(
    $input: UpsertApplicationOptionAndRoomCheckApplicationPeriodInput!
  ) {
    upsertApplicationOption(input: $input) {
      application_option {
        application_type_id
        application_url
        cancellation_body
        caution_body
        caution_url
        caution_url_text
        company_id
        complete_body
        complete_title
        complete_url
        complete_url_text
        created_at
        deleted_at
        id
        is_checked_url
        is_enabled
        status
        updated_at
      }
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      room_check_application_period {
        id
        status
        company_id
        reckoning_date_type_id
        display_period
        reception_period
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const createSearchCondition = /* GraphQL */ `
  mutation CreateSearchCondition($input: CreateSearchConditionInput!) {
    createSearchCondition(input: $input) {
      id
      status
      company {
        id
        status
        name
        name_kana
        stock_signage
        zip_code
        prefecture_id
        address
        tel
        fax
        mail
        url
        business_hours
        owner_function_enabled
        regular_holiday
        emergency_name
        emergency_tel
        emergency_time
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
        business_hours_text
        regular_holiday_text
        emergency_time_text
        privacy_policy_url
      }
      createdBy {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      name
      isDefault
      screen
      condition
      createdAt
      updatedAt
      deletedAt
    }
  }
`
export const deleteSearchCondition = /* GraphQL */ `
  mutation DeleteSearchCondition($input: DeleteSearchConditionInput!) {
    deleteSearchCondition(input: $input) {
      noop
    }
  }
`
export const updateSearchCondition = /* GraphQL */ `
  mutation UpdateSearchCondition($input: UpdateSearchConditionInput!) {
    updateSearchCondition(input: $input) {
      id
      status
      company {
        id
        status
        name
        name_kana
        stock_signage
        zip_code
        prefecture_id
        address
        tel
        fax
        mail
        url
        business_hours
        owner_function_enabled
        regular_holiday
        emergency_name
        emergency_tel
        emergency_time
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
        business_hours_text
        regular_holiday_text
        emergency_time_text
        privacy_policy_url
      }
      createdBy {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      name
      isDefault
      screen
      condition
      createdAt
      updatedAt
      deletedAt
    }
  }
`
export const deletePackage = /* GraphQL */ `
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      noop
    }
  }
`
export const updateKeyReceiptApplication = /* GraphQL */ `
  mutation UpdateKeyReceiptApplication(
    $input: UpdateKeyReceiptApplicationInput!
  ) {
    updateKeyReceiptApplication(input: $input) {
      id
      status
      company_id
      customer_id
      building_id
      room_id
      application_type_id
      application_state_id
      content
      remark
      contract_id
      created_at
      updated_at
      deleted_at
    }
  }
`
